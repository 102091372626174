<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Funcionários</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input
            v-model="searchQuery"
            placeholder="Buscar funcionário"
            class="input-with-select"
            clearable
            @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateEmployee">
            Adicionar Funcionário
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, employees)">
        <div v-if="employees.data.length" class="w-100">
          <div
            v-for="employee in employees.data"
            :key="employee.id"
            class="w-100 mb--md">
            <getecma-employee-card
              :employee="employee" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhum funcionário encontrado" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateEmployee } from '@/modules/employee/employee.routes';
import { fetchEmployees } from '@/modules/employee/employee.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaEmployeeCard from '@/modules/employee/components/EmployeeCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceEmployees',
  components: {
    GetecmaEmployeeCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Funcionários', path: this.$route.path },
      ],
      employees: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    this.fetchEmployees();
  },
  methods: {
    isMinLayout,
    fetchEmployees() {
      this.employees.loading = true;
      fetchEmployees({ limit: this.employees.pagination.limit, page: this.employees.pagination.page, search: this.searchQuery })
        .then(({ employees, total_employees: total }) => {
          if (this.searchQuery === '') {
            this.employees.data.push(...employees);
          } else {
            this.employees.data = employees;
          }
          Object.assign(this.employees.pagination, { total: parseInt(total), lastPage: Math.ceil(total / this.employees.pagination.limit) });
        })
        .catch(() => toastError('Erro ao obter funcionários.'))
        .finally(() => { this.employees.loading = false; });
    },
    onCreateEmployee() {
      goToCreateEmployee(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchEmployees();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.employees.pagination.page = 1;
      this.employees.pagination.lastPage = 0;
      this.employees.data = [];
      this.fetchEmployees();
    },
    onClearSearch() {
      this.searchQuery = '';
      this.onSearchInput();
    },
  },
};
</script>
