<template>
  <getecma-card :bg-color="bgColor">
    <!-- NAMES -->
    <getecma-container direction="column" grow="1" style="padding-left: 15px;">
      <getecma-header size="md" class="d-flex align-items-center text-overflow--truncate-1">
        <getecma-employee-link :id="employee.id">
          {{ employee.name }}
        </getecma-employee-link>
      </getecma-header>
    </getecma-container>
    <getecma-container v-if="editing" align-items="center" class="me--xxl" style="width:130px">
      <!-- ACTION BUTTON -->
      <getecma-dropdown v-if="isAdmin" :min-width="90" style="margin-top:-10px">
        <!-- BUTTON -->
        <getecma-menu-item slot="content" :margin-top="0" padding="0">
          <dots-icon size="sm" fill="theme-200" />
        </getecma-menu-item>
        <!-- MENU ITEMS -->
        <template #items>
          <getecma-employee-link :id="employee.id" action="edit">
            <el-dropdown-item class="color--theme-100">
                <edit-icon size="sm" class="me--sm" />
                Editar
              </el-dropdown-item>
          </getecma-employee-link>
          <div v-if="isAdmin">
            <a @click="showDeletedModal()">
              <el-dropdown-item class="color--theme-100">
                  <getecma-icon size="md" icon="trash" />
                  Excluir
                </el-dropdown-item>
            </a>
          </div>
        </template>
      </getecma-dropdown>
    </getecma-container>
    <!-- MODALS -->
    <getecma-modal-delete
      v-if="isShowDeletedModal"
      ref="modalDelete"
      content="Você realmente deseja excluir este funcionário?"
      @close="isShowDeletedModal = false"
      @confirm="onDeleteEmployee()">
    </getecma-modal-delete>
  </getecma-card>
</template>

<script>

import { getters } from '@/modules/user/user.store';
import { removeEmployee } from '@/modules/employee/employee.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaCard from '@/components/card/Card.vue';
import GetecmaEmployeeLink from '@/modules/employee/components/EmployeeLink.vue';
import GetecmaDropdown from '@/components/dropdown/Dropdown.vue';
import EditIcon from '@/components/icon/Edit.vue';
import DotsIcon from '@/components/icon/Dots.vue';
import GetecmaMenuItem from '@/components/menu/components/MenuItem.vue';

export default {
  name: 'EmployeeCard',
  components: {
    GetecmaEmployeeLink,
    GetecmaCard,
    GetecmaDropdown,
    EditIcon,
    DotsIcon,
    GetecmaMenuItem,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      default: true,
    },
    bgColor: {
      type: String,
      default: 'theme-100-900',
    },
  },
  data() {
    return {
      performer: getters.getUser(),
      loading: true,
      isShowDeletedModal: false,
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  methods: {
    isMinLayout,
    showDeletedModal() {
      this.isShowDeletedModal = true;
    },
    showConfirmModal() {
      this.isShowConfirmModal = true;
    },
    onDeleteEmployee() {
      this.isShowDeletedModal = false;
      removeEmployee(this.employee)
        .then(() => this.$router.go(0));
    },
  },
};
</script>
